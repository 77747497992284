import React from "react"
import styled from "@emotion/styled"

import NotificationContext, {
  useNotificationContext,
} from "../../context/NotificationContext"
import Typography from "../ui/Typography"
import { DesktopContainer, MobileContainer } from "../new-ui/shared"
import { fadeIn, slideDown } from "../new-ui/shared/keyframes"

const Container = styled.div`
  position: fixed;
  top: calc(${(props) => props.theme.navbar.height} + 10px);

  ${(props) =>
    props.promotionalVisible &&
    `
    top: calc(${props.theme.navbar.promotional} + 10px);

  `}
  right: 0;

  width: 100%;
  z-index: 100;
  padding: 0 5px;

  transform: opacity 200ms;
  opacity: ${(props) => (props.visible ? "visible" : "hidden")};

  white-space: pre;

  :after {
    transition: visibility 0ms;
    visibility: hidden;
    margin-top: -10px;
    content: "";
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 12px solid white;
    top: 0;
    right: 20px;
    z-index: 1;
  }

  ${(props) =>
    props.visible &&
    `  
    :after {
      transition: visibility 400ms 200ms ease-in;
      visibility: visible;
    }
  `}

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    width: 400px;
  }
`

const Notification = styled.div`
  box-shadow: 0 10px 100px rgba(0, 0, 0, 0.19), 0 6px 76px rgba(0, 0, 0, 0.03);

  color: ${(props) => props.theme.colors.darkest};

  padding: 16px;

  background-color: ${(props) => props.theme.colors.lightest};
  animation: ${slideDown} 400ms, ${fadeIn} 400ms;
  white-space: normal;

  .dismiss {
    ${Typography.Base};
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 15px;
    color: ${(props) => props.theme.colors.darkest};
  }

  &:not(:last-of-type) {
    margin-bottom: 0.4rem;
  }
`

const NotificationContainer = ({ promotionalVisible }) => {
  const { dismissNotification } = useNotificationContext()
  return (
    <NotificationContext.Consumer>
      {({ notifications, show }) => {
        return (
          <Container
            visible={notifications?.length > 0}
            promotionalVisible={promotionalVisible}
          >
            {notifications.map((n, index) => (
              <Notification show={show[n.id]} key={index}>
                <MobileContainer>
                  {n.mobileBody || (
                    <div>
                      {n.body}{" "}
                      <div
                        onClick={() => dismissNotification(n.id)}
                        className="dismiss"
                      >
                        &#215;
                      </div>
                    </div>
                  )}
                </MobileContainer>
                <DesktopContainer>
                  {n.desktopBody || (
                    <div>
                      {n.body}{" "}
                      <div
                        onClick={() => dismissNotification(n.id)}
                        className="dismiss"
                      >
                        &#215;
                      </div>
                    </div>
                  )}
                </DesktopContainer>
              </Notification>
            ))}
          </Container>
        )
      }}
    </NotificationContext.Consumer>
  )
}

export default NotificationContainer
